const requiredFields = ['type', 'tradeDate', 'wallet'];

const defaultTxCheck = (key) => (tx) => tx.type === key;
export const TRANSACTION_TYPE = {
  TRADE: {
    key: 'TRADE',
    requiredFields,
    amounts: [
      ['n', true],
      ['p', true],
      ['f', false],
    ],
    dbType: true,
    category: 'Tauschgeschäfte',
    txCheck: defaultTxCheck('TRADE'),
  },
  // income KAP
  PNL: {
    key: 'PNL',
    requiredFields,
    amounts: [['-', true]],
    dbType: true,
    txCheck: defaultTxCheck('PNL'),
  },
  DEPOSIT: {
    key: 'DEPOSIT',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    category: 'Guthaben',
    txCheck: (tx) => tx.isDeposit() && !tx.isTransferDeposit(),
  },
  WITHDRAWAL: {
    key: 'WITHDRAWAL',
    requiredFields,
    amounts: [
      ['n', true],
      ['f', false],
    ],
    dbType: true,
    txCheck: (tx) => tx.isWithdrawal() && !tx.isTransferWithdrawal(),
  },
  TRANSFER: {
    key: 'TRANSFER',
    requiredFields: ['type', 'tradeDate', 'wallet', 'walletB'],
    amounts: [
      ['n', true],
      ['f', false],
    ],
    dbType: false,
    txCheck: (tx) => tx.isTransfer(),
  },
  // income free, sell EkSt
  BORROWING: {
    key: 'BORROWING',
    requiredFields,
    amounts: [
      ['p', true],
      ['f', false],
    ],
    dbType: true,
    txCheck: defaultTxCheck('BORROWING'),
  },
  REPAYMENT: {
    key: 'REPAYMENT',
    requiredFields,
    amounts: [
      ['n', true],
      ['f', false],
    ],
    dbType: true,
    txCheck: defaultTxCheck('REPAYMENT'),
  },
  // income EkSt, sell tax free
  STAKING: {
    key: 'STAKING',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    category: 'DeFi',
    txCheck: defaultTxCheck('STAKING'),
  },
  // income EkSt, sell tax free
  LENDING: {
    key: 'LENDING',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('LENDING'),
  },
  ADD_LIQUIDITY: {
    key: 'ADD_LIQUIDITY',
    requiredFields,
    amounts: [
      ['addlm1', true],
      ['addlm2', true],
      ['addlm', true],
    ],
    dbType: true,
    txCheck: defaultTxCheck('ADD_LIQUIDITY'),
  },
  REMOVE_LIQUIDITY: {
    key: 'REMOVE_LIQUIDITY',
    requiredFields,
    amounts: [
      ['removelm1', true],
      ['removelm2', true],
      ['removelm', true],
    ],
    dbType: true,
    txCheck: defaultTxCheck('REMOVE_LIQUIDITY'),
  },
  // income KAP
  LM: {
    key: 'LM',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('LM'),
  },
  // income tax free, sell tax free
  AIRDROP: {
    key: 'AIRDROP',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('AIRDROP'),
  },
  // income tax free, sell tax free
  FORK: {
    key: 'FORK',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('FORK'),
  },
  TOKEN_SPLIT: {
    key: 'TOKEN_SPLIT',
    requiredFields,
    amounts: [['-', true]],
    dbType: true,
    txCheck: defaultTxCheck('TOKEN_SPLIT'),
  },
  PAYMENT: {
    key: 'PAYMENT',
    requiredFields,
    amounts: [
      ['n', true],
      ['f', false],
    ],
    dbType: true,
    category: 'Anderes',
    txCheck: defaultTxCheck('PAYMENT'),
  },
  FEE: {
    key: 'FEE',
    // fee* gets copied from the sell* in the resolvers
    requiredFields,
    amounts: [['f', true]],
    dbType: true,
    txCheck: defaultTxCheck('FEE'),
  },
  OTHER_INCOME: {
    key: 'OTHER_INCOME',
    requiredFields,
    amounts: [['-', true]],
    dbType: true,
    txCheck: defaultTxCheck('OTHER_INCOME'),
  },
  LOST_COINS: {
    key: 'LOST_COINS',
    requiredFields,
    amounts: [
      ['n', true],
      ['f', false],
    ],
    dbType: true,
    txCheck: defaultTxCheck('LOST_COINS'),
  },
  GIFT: {
    key: 'GIFT',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('GIFT'),
  },
  REFERRAL_REWARDS: {
    key: 'REFERRAL_REWARDS',
    requiredFields,
    amounts: [['-', true]],
    dbType: true,
    txCheck: defaultTxCheck('REFERRAL_REWARDS'),
  },
  CASHBACK: {
    key: 'CASHBACK',
    requiredFields,
    amounts: [['p', true]],
    dbType: true,
    txCheck: defaultTxCheck('CASHBACK'),
  },
};
export const transactionTypes = Object.values(TRANSACTION_TYPE);

export const locals = {
  eng: { key: 'en', label: 'English', short: 'En' },
  ger: { key: 'de', label: 'Deutsch', short: 'De' },
};

export const regions = {
  ger: { key: 'DE', label: 'Deutschland', short: 'De' },
  eng: { key: 'AT', label: 'Österreich', short: 'At' },
  int: { key: 'INT', label: 'International', short: 'Int' },
};

export const API_STATES = {
  OKAY: 'OKAY',
  SYNCING: 'SYNCING',
  OFFLINE: 'OFFLINE',
};

export const FILE_IMPORT_STATES = {
  LOADING: 'LOADING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};

export const TRANSACTION_SOURCES = {
  MANUALLY: 'MANUALLY',
  IMPORTED_FILE: 'IMPORTED_FILE',
  API: 'API',
};

export const ASSET_TYPES = {
  CRYPTO: 'CRYPTO',
  FIAT: 'FIAT',
  LM_TOKEN: 'LM_TOKEN',
};

export const WARNING_TYPES = {
  MISSING_SOURCE_OF_OUTFLOW: 'MISSING_SOURCE_OF_OUTFLOW',
  IS_DEPOSIT_WITHOUT_WITHDRAWAL: 'IS_DEPOSIT_WITHOUT_WITHDRAWAL',
  IS_WITHDRAWAL_WITHOUT_DEPOSIT: 'IS_WITHDRAWAL_WITHOUT_DEPOSIT',
};

export const GAIN_TYPES = {
  SHORT_TERM_GAIN: 'SHORT_TERM_GAIN',
  LONG_TERM_GAIN: 'LONG_TERM_GAIN',
  MARGIN_GAIN: 'MARGIN_GAIN',
  CAPITAL_GAIN: 'CAPITAL_GAIN',
  STAKING_INTERESTS: 'STAKING_INTERESTS',
  LENDING_INTERESTS: 'LENDING_INTERESTS',
  LM_INTERESTS: 'LM_INTERESTS',
  FEE_COSTS: 'FEE_COSTS',
  AIRDROP_GIFT: 'AIRDROP_GIFT',
  FORK_GIFT: 'FORK_GIFT',
  OTHER_INCOME: 'OTHER_INCOME',
  LOST_COINS: 'LOST_COINS',
  GIFT: 'GIFT',
  REFERRAL_REWARDS: 'REFERRAL_REWARDS',
  CASHBACK: 'CASHBACK',
};

export const TAX_REGIONS = {
  DE: 'DE',
  AT: 'AT',
  INTERNATIONAL: 'INTERNATIONAL',
};

export const TAX_TYPES = {
  INCOME: 'INCOME',
  OTHERINCOME: 'OTHERINCOME',
  CGT: 'CGT',
  CRYPTOTAX: 'CRYPTOTAX',
  FREE: 'FREE',
  BUSINESS: 'BUSINESS',
};

export const PRICE_DIRECTIONS = {
  BUY_IN: 'BUY_IN',
  SELL_OUT: 'SELL_OUT',
};

// Adapt for adding TaxYears
export const lastSevenYears = Array.from(new Array(9)).map(
  (_, index) => new Date().getFullYear() - index
);

export const tokenKey = 'token';
