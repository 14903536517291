import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SignUpButton from '../../components/structure/SignUpButton';
import BannerStroke from '../../media/images/BannerStroke.svg';
import BannerStrokeMobileLarge from '../../media/images/BannerStrokeMobile1.svg';
import BannerStrokeMobileSmall from '../../media/images/BannerStrokeMobile2.svg';
import IcActionsCheckSimple from '../../media/icons/ic-actions-check-simple.svg';
import Onboarding from '../Onboarding';

function MarketingMotivationBanner({
  textAlign = 'center',
  color,
  showBenefits = true,
  showSignUpButton = true,
}) {
  const { t } = useTranslation('mainpage');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  if (isMobile) {
    return (
      <div
        style={{
          margin: '2rem 0 1.5rem 0',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          <BannerStrokeMobileSmall
            style={{
              position: 'absolute',
              left: -5,
              width: 'calc(100% + 20px)',
              bottom: 0,
              zIndex: 1,
            }}
          />
          <span style={{ zIndex: 2, position: 'relative', color }}>
            {t('Krypto')}
          </span>
        </span>

        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          <BannerStrokeMobileLarge
            style={{
              position: 'absolute',
              left: -10,
              width: 'calc(100% + 20px)',
              bottom: 0,
              zIndex: 1,
            }}
          />
          <span style={{ zIndex: 2, position: 'relative', color }}>
            {t('Steuererklärung')}
          </span>
        </span>

        <span
          style={{
            fontSize: 36,
            fontWeight: 'bold',
            color,
            textAlign: 'center',
            zIndex: 2,
            position: 'relative',
            margin: 0,
          }}
        >
          {t('in wenigen Minuten.')}
        </span>
      </div>
    );
  }

  return (
    <div style={{ margin: '4rem 0 1.5rem 0' }}>
      <span
        style={{
          fontSize: 80,
          fontWeight: 'bold',
          zIndex: 2,
          position: 'relative',
          margin: 0,
          textAlign,
          width: textAlign === 'center' ? '100%' : undefined,
          display: textAlign === 'center' ? 'inline-block' : undefined,
        }}
      >
        <BannerStroke
          style={{
            position: 'absolute',
            left: textAlign === 'center' ? -20 : 0,
            width: '105%',
            bottom: '-0.5rem',
            zIndex: 1,
          }}
        />
        <span style={{ zIndex: 2, position: 'relative', color }}>
          {t('Krypto Steuererklärung')}
        </span>
      </span>
      <div />
      <span
        style={{
          fontSize: 80,
          fontWeight: 'bold',
          color,
          textAlign,
          width: textAlign === 'center' ? '100%' : undefined,
          display: textAlign === 'center' ? 'inline-block' : undefined,
          margin: '-0.5rem 0 0 0',
        }}
      >
        {t('in wenigen Minuten.')}
      </span>
      {showBenefits && (
        <>
          <Box sx={{ height: '2rem' }} /> {}
          <Grid container gap="1rem">
            <BenefitChip>
              {t('Börsen einfach per API integrierbar')}
            </BenefitChip>
            <BenefitChip>
              {t('Rechtskonform nach Konten separatem FIFO')}
            </BenefitChip>
            <BenefitChip>
              {t(
                'Spot und Margin Trades, Futures, Lending, Staking, Hardforks, Liquidity Mining, u.a.'
              )}
            </BenefitChip>
          </Grid>
        </>
      )}
      <Box sx={{ height: '2.5rem' }} /> {}
      {showSignUpButton && (
        <div style={{ marginTop: '2rem', textAlign: 'left' }}>
          <SignUpButton
            sx={{
              margin: '0',
              height: 64,
              width: 'fit-content',
              backgroundColor: 'primary.main',
              color: 'custom.white',
            }}
            hoverStyle={{
              bgcolor: 'secondary.main',
              color: 'primary.main',
            }}
            label={t('Jetzt anmelden')}
          />

          <div style={{ position: 'relative' }}>
            <Onboarding isMobile={isMobile} />
          </div>
        </div>
      )}
    </div>
  );
}

const BenefitChip = ({ children }) => (
  <Grid item xs={12} md={'auto'}>
    <Box
      sx={{
        backgroundColor: 'primary.Q',
        borderRadius: '8px',
        padding: '4px 6px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'success.main',
          borderRadius: 25,
          padding: '2px',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <IcActionsCheckSimple style={{ width: '17px', height: '17px' }} />
      </Box>

      <Typography fontWeight="bold">{children}</Typography>
    </Box>
  </Grid>
);

export default MarketingMotivationBanner;
