import { Button, Grid, useTheme } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

const Onboarding = ({}) => {
  const router = useRouter();
  const { t } = useTranslation('mainpage');
  const { palette } = useTheme();

  const [loadPilot, setLoadPilot] = useState(false);
  useEffect(() => setLoadPilot(true), []);

  return (
    <Grid container spacing={3} direction="column">
      <Grid item xs={12}>
        {loadPilot && (
          <div
            style={{
              marginLeft: '-45px',
              marginTop: '10px',
              // border: '2px dashed red',
              // minHeight: '32px',
            }}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="61336d69da857a001e80ae5d"
            data-style-height="32px"
            data-theme="light"
            data-min-review-count="50"
          >
            <a
              href="https://uk.trustpilot.com/review/chain.report"
              target="_blank"
              rel="noopener"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

function PartnerButton({ name, logo, onClick }) {
  const { typography, palette } = useTheme();

  return (
    <Grid item xs={12} md={4}>
      <Button
        fullWidth
        variant="outlined"
        onClick={onClick}
        startIcon={
          <Image src={logo} width={48} height={48} priority alt={name} />
        }
        sx={{
          ...typography.h3,
          border: `2px solid ${palette.primary.O}`,
          backgroundColor: palette.custom.white,
          ':hover': {
            border: `2px solid ${palette.custom.deFiChain}`,
            backgroundColor: palette.custom.white,
          },
          '.MuiButton-startIcon': { marginRight: '24px' },
          height: '120px',
        }}
      >
        {name}
      </Button>
    </Grid>
  );
}

export default Onboarding;
